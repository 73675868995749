<template>
    <div class="car-wrapper">
        <div class="car-status-anage" @scroll="scrollHandle">
            <div class="car-state-title">
                <div
                    v-for="(item, index) in siteData"
                    :key="index"
                    @click="selectStation(index, item)"
                    :class="`grid-content bg-purple${setSelect === index ? ' contene_active' : ''}`"
                    :title="item.mixstation_name"
                >
                    <div :class="`grid-title${setSelect === index ? ' select-style' : ''}`">
                        {{ item.mixstation_name }}
                    </div>
                    <div class="select-style-bottom" v-show="setSelect === index">
                        <div class="bottom_div"></div>
                    </div>
                    <!-- <div class="select-style" v-show="setSelect === index">
                        <span class="iconfont" style="z-index:3">&#xe66d;</span>
                        <span class="border-right"></span>
                    </div> -->
                </div>
            </div>
            <div class="vehicle-field">
                <div class="outside-car">
                    <div class="car_main">
                        <div class="main_head_title">
                            场内车辆
                        </div>
                        <div class="main_head_main">
                            <div class="available available_usable">
                                <h2 class="title-class">
                                    调度员可用车辆：{{ carList.kycl.length }}
                                    <i class="iconfont iconxiegang-01 title_icon1"></i>
                                </h2>
                                <draggable
                                    class="show-car-info"
                                    v-model="carList.kycl"
                                    group="viid"
                                    @start="drag=true"
                                    @end="drag=false"
                                    @update="dragUpdate1"
                                >
                                    <div
                                        v-for="item in carList.kycl"
                                        :key="item.kycl"
                                    >
                                        <div class="show-car-info">
                                            <dl @click="lookCarInfo(item)">
                                                <dt>{{ item.plate_small_number || ' ' }}</dt>
                                                <dd><span class="iconfont">&#xe79d;</span></dd>
                                                <dd v-if="!item.driver_phone" class="car-angle-sign-driver car-angle-sign">
                                                    <div>
                                                        <div>
                                                            无司机
                                                        </div>
                                                    </div>
                                                </dd>
                                                <dd v-else-if="item.pull_water===1" class="car-angle-sign-pull car-angle-sign">
                                                    <div>
                                                        拉水中
                                                    </div>
                                                </dd>
                                                <dd
                                                    v-else-if="item.status!==1&&item.status!==2"
                                                    class="car-angle-sign-task car-angle-sign"
                                                >
                                                    <div>
                                                        有任务
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </draggable>
                            </div>
                            <div class="main_bottom">
                                <div class="available available_one">
                                    <h2 class="title-class">
                                        已被调度等待装料车辆：{{ carList.zlcl.length }}
                                        <i class="iconfont iconxiegang-01 title_icon2"></i>
                                    </h2>
                                    <div class="show-car-info blue-class">
                                        <dl
                                            v-for="item in carList.zlcl"
                                            :key="item.viid"
                                            @click="lookCarInfo(item)"
                                        >
                                            <dt>{{ item.plate_small_number || ' ' }}</dt>
                                            <dd><span class="iconfont">&#xe79d;</span></dd>
                                        </dl>
                                    </div>
                                </div>
                                <div class="available available_two">
                                    <h2 class="title-class">
                                        已被调度正在生产车辆：{{ carList.sccl.length }}
                                        <i class="iconfont iconxiegang-01 title_icon3"></i>
                                    </h2>
                                    <div class="show-car-info origin-class">
                                        <dl
                                            v-for="item in carList.sccl"
                                            :key="item.viid"
                                            @click="lookCarInfo(item)"
                                        >
                                            <dt>{{ item.plate_small_number || '' }}</dt>
                                            <dd><span class="iconfont">&#xe79d;</span></dd>
                                        </dl>
                                    </div>
                                </div>
                                <div class="available available_one">
                                    <h2 class="title-class">
                                        其他车辆：{{ carList.qtcl.length }}
                                        <i class="iconfont iconxiegang-01 title_icon2"></i>
                                    </h2>
                                    <div class="show-car-info blue-class">
                                        <div
                                            v-for="item in carList.qtcl"
                                            :key="item.viid"
                                        >
                                            <div class="show-car-info">
                                                <dl @click="lookCarInfo(item)">
                                                    <dt>{{ item.plate_small_number || ' ' }}</dt>
                                                    <dd><span class="iconfont">&#xe79d;</span></dd>
                                                    <dd v-if="!item.driver_phone" class="car-angle-sign-driver car-angle-sign">
                                                        <div>
                                                            无司机
                                                        </div>
                                                    </dd>
                                                    <dd v-else-if="item.pull_water===1" class="car-angle-sign-pull car-angle-sign">
                                                        <div>
                                                            拉水中
                                                        </div>
                                                    </dd>
                                                    <dd
                                                        v-else-if="item.status!==1&&item.status!==2"
                                                        class="car-angle-sign-task car-angle-sign"
                                                    >
                                                        <div>
                                                            有任务
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="car_main car_main_2">
                        <div class="main_left">
                            <div class="main_head_title main_head_title2">
                                场外车辆
                            </div>
                            <div class="available only-available-style">
                                <div class="show-car-info">
                                    <div class="show-car-info grey-class">
                                        <h2 class="title-class">
                                            车辆数：{{ carList.cwcl.length }}
                                            <i class="iconfont iconxiegang-01 title_icon4"></i>
                                        </h2>
                                        <div
                                            v-for="(item) in carList.cwcl"
                                            :key="item.viid"
                                        >
                                            <dl @click="lookCarInfo(item)">
                                                <dt>{{ item.plate_small_number || ' ' }}</dt>
                                                <dd><span class="iconfont">&#xe79d;</span></dd>

                                                <dd
                                                    class="car-angle-sign-driver car-angle-sign"
                                                    v-if="[9, 10, 6, 7, 73, 77, 8].indexOf(item.status) < 0"
                                                >
                                                    <div>
                                                        无任务
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main_right">
                            <div class="main_head_title main_head_title3">
                                闲置车辆
                            </div>
                            <div class="available only-available-style only-available-style2">
                                <div class="show-car-info">
                                    <div class="show-car-info relve-class">
                                        <h2 class="title-class">
                                            车辆数：{{ carList.xzcl.length }}
                                            <i class="iconfont iconxiegang-01 title_icon5"></i>
                                        </h2>
                                        <div
                                            v-for="(item) in carList.xzcl"
                                            :key="item.viid"
                                        >
                                            <dl @click="lookCarInfo(item)">
                                                <dt>{{ item.plate_small_number || ' ' }}</dt>
                                                <dd><span class="iconfont">&#xe79d;</span></dd>

                                                <dd
                                                    class="car-angle-sign-driver car-angle-sign"
                                                    v-if="[9, 10, 6, 7, 73, 77, 8].indexOf(item.status) < 0"
                                                >
                                                    <div>
                                                        无任务
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="car_main car_main_2">
                        <div class="main_head_title main_head_title4">
                            维修车辆
                        </div>
                        <div class="available only-available-style only-available-style3">
                            <div class="show-car-info">
                                <div class="show-car-info red-class">
                                    <h2 class="title-class title-class2">
                                        车辆数：{{ carList.wxcl.length }}
                                        <i class="iconfont iconxiegang-01 title_icon6"></i>
                                    </h2>
                                    <div
                                        v-for="(item) in carList.wxcl"
                                        :key="item.viid"
                                    >
                                        <dl @click="lookCarInfo(item)">
                                            <dt>{{ item.plate_small_number || ' ' }}</dt>
                                            <dd><span class="iconfont">&#xe79d;</span></dd>

                                            <dd
                                                class="car-angle-sign-driver car-angle-sign"
                                                v-if="[9, 10, 6, 7, 73, 77, 8].indexOf(item.status) < 0"
                                            >
                                                <div>
                                                    无任务
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 车辆详细信息 -->
            <template>
                <div class="car-detailed-info" ref="carInfo" :style="{'visibility': clickCarInfo ? 'visible' : 'hidden'}">
                    <h3>车号： {{ carInfo.plate_small_number }}</h3>
                    <div class="car-detailed-box">
                        <p>车牌号：{{ carInfo.plate_number }}</p>
                        <p>司机：{{ carInfo.driver_name }}</p>
                        <p>联系电话：{{ carInfo.driver_phone }}</p>
                        <p>进场时间：{{ carInfo.into_time }}</p>
                        <p>工程：{{ carInfo.finish_employment_name }}</p>
                        <p>发货方量：{{ carInfo.finish_volume }}</p>
                        <div class="button-style">
                            <div>
                                <h5
                                    @click="setFactoryAndRepair(carInfo.viid, 'F')"
                                    v-if="carInfo.status != 9 && carInfo.status != 10"
                                >
                                    {{ carInfo.is_in_plant ? '设置出场' : '设置进场' }}
                                </h5>
                                <h5 @click="setFactoryAndRepair(carInfo.viid, 'R', 'ru')" v-else>
                                    设置入场
                                </h5>
                            </div>
                            <div>
                                <h5
                                    v-if="carInfo.status != 9 && carInfo.status != 10"
                                    @click="setFactoryAndRepair(carInfo.viid, 'R', 'xiu')"
                                >
                                    设置维修
                                </h5>
                                <h5 v-else @click="setFactoryAndRepair(carInfo.viid, 'R', 'chu')">
                                    设置出场
                                </h5>
                            </div>
                            <div>
                                <h5
                                    v-if="carInfo.status != 9 && carInfo.status != 10"
                                >
                                    设置闲置
                                </h5>
                                <h5 v-else>
                                    设置闲置
                                </h5>
                                <!-- <h5
                                    v-if="carInfo.status != 9 && carInfo.status != 10"
                                    @click="setFactoryAndRepair(carInfo.viid, 'R', 'xiu')"
                                >
                                    设置闲置
                                </h5>
                                <h5 v-else @click="setFactoryAndRepair(carInfo.viid, 'R', 'chu')">
                                    设置闲置
                                </h5> -->
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>


<script>
import { eventBus } from '@/common/eventBus.ts';
import draggable from 'vuedraggable';
export default {
    components: { draggable },
    data() {
        return {
            siteData: [],
            // 当前场站下标
            setSelect: 0,
            // 弹窗状态
            clickCarInfo: false,
            // 选中的车辆
            lookCarIndex: 0,
            // 点击次数
            count: 0,
            // 车辆列表
            carList: {
                kycl: [],
                zlcl: [],
                sccl: [],
                qtcl: [],
                cwcl: [],
                wxcl: [],
                xzcl: [],
            },
            carTypeArr: [
                {
                    id: [1, 2, 3, 4, 5, 21 ],
                    title: '场内车辆',
                    list: [
                        { id: [1, 2], name: '调度员可用车辆' },
                        { id: [3, 21], name: '已被调度等待装料车辆' },
                        { id: [4, 5], name: '已被调度正在生产车辆' },
                    ],
                },
                {
                    id: [6, 7, 8, 73, 77],
                    title: '场外车辆',
                },
                {
                    id: [9, 10],
                    title: '维修车辆',
                },
            ],
            currentStation: '',
            nowCarIndex: 0,
            carStatus: {},
            carInfo: {},
            viid: '',
        };
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('CarStatus');
    },
    watch: {
        carStatus: {
            deep: true,
            immediate: true,
            handler: function (val) {
                if (val.mixstation_code === this.currentStation) {
                    const arr = [...this.carList.kycl, ...this.carList.zlcl, ...this.carList.sccl, ...this.carList.cwcl, ...this.carList.wxcl];
                    arr.push(...this.carList.xzcl);
                    arr.forEach(item => {
                        if (item.plate_number === val.car_license) {
                            this.getVehicle(this.siteData[this.setSelect].mixstation_code);
                        }
                    });
                }
            },
        },
    },
    mounted() {
        eventBus.$on('CarStatus', data => {
            this.carStatus = JSON.parse(data);
        });
        document.addEventListener('click', () => {
            this.clickCarInfo = false;
        });
        this.gitSiteData();
    },
    methods: {
        // 页面滚动事件
        scrollHandle() {
            this.clickCarInfo = false;
        },
        // 查看车辆详细信息
        lookCarInfo(item) {
            this.carInfo = item;
            this.viid = item.viid;
            const infoDom = this.$refs.carInfo;
            const domHeight = infoDom.offsetHeight;
            const x = event.pageX + 10;
            const y = event.pageY - domHeight;
            infoDom.style.position = 'fixed';
            infoDom.style.left = x + 'px';
            infoDom.style.top = y + 'px';
            this.count++;
            setTimeout(() => {
                this.clickCarInfo = true;
            }, 100);
            if (this.count % 2 === 0 && item.viid === this.viid) {
                this.clickCarInfo = false;
            }
            this.viid = item.viid;
        },
        // 选择切换
        selectStation(index, tabs) {
            this.setSelect = index;
            // 获取维修列表
            this.getVehicle(tabs.mixstation_code);
        },
        // 设置出场和维修
        setFactoryAndRepair(id, clickId, repair) {
            // clickId    R = Repair维修、  F = Factory出厂
            // repair   ru 维修完成入场  chu 维修完成出厂  xiu 正常维修
            if (clickId === 'F') {
                this.setFactory(id);
            } else {
                this.setRepair(id, repair);
            }
        },
        // 设置出厂
        setFactory(id) {
            const arr = [...this.carList.kycl, ...this.carList.zlcl, ...this.carList.sccl, ...this.carList.cwcl, ...this.carList.wxcl];
            arr.push(...this.carList.xzcl);
            arr.push(...this.carList.qtcl);
            console.log(arr, 'arr');
            const obj = arr.filter(item => item.viid === id);
            const clickCar = obj[0].is_in_plant;
            let factoryStatus;
            if (clickCar) {
                factoryStatus = 0;
            } else {
                factoryStatus = 1;
            }
            this.$axios
                .put(`/interfaceApi/production/vehicleinfo/modify_out/${id}/${factoryStatus}`)
                .then(res => {
                    this.$message.success(res);
                    this.getVehicle(this.siteData[this.setSelect].mixstation_code);
                    this.clickCarInfo = false;
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                    this.clickCarInfo = false;
                });
        },
        // 设置维修/入场/出场
        setRepair(id, status) {
            let factoryStatus;
            if (status === 'ru') {
                factoryStatus = 1;
            } else if (status === 'chu') {
                factoryStatus = 2;
            } else if (status === 'xiu') {
                factoryStatus = 0;
            }
            this.$axios
                .put(`/interfaceApi/production/vehicleinfo/modify_service/${id}/${factoryStatus}`)
                .then(res => {
                    this.$message.success(res);
                    this.getVehicle(this.siteData[this.setSelect].mixstation_code);
                    this.clickCarInfo = false;
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 获取场站信息
        gitSiteData() {
            this.$axios
                // .get('/interfaceApi/production/mixstation/mixstation')
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=' + this.$takeTokenParameters('Uid'))
                .then(res => {
                    if (res.length > 0) {
                        // 当前站点默认站点
                        // this.siteData = res;
                        // this.getVehicle(res[0].mixstation_code);
                        this.siteData = [];
                        res.forEach(item => {
                            this.siteData.push({
                                mixstation_code: item.station_id,
                                mixstation_name: item.name,
                                capacity: item.capacity,
                            });
                        });
                        this.getVehicle(res[0].station_id);
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 获取车辆列表
        getVehicle(code) {
            this.currentStation = code;
            this.$axios
                // .get(`/interfaceApi/production/vehicleinfo/get_vehicle/${this.siteData[0].mixstation_code}`)
                .get('/interfaceApi/production/vehicleinfo/get_vehicle/' + code)
                .then(res => {
                    const obj = res;
                    if (!res.xzcl) {
                        obj.xzcl = [];
                    }
                    this.carList = obj || {};
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },


        // 点击拖动位置更换后的事件
        dragUpdate1(e) {
            const updateCars = [];
            const newIndex = e.newIndex;
            // const oldIndex = e.oldIndex;
            // const beginIndex = newIndex > 0 ? 0 : oldIndex;
            const tempCars = this.carList.kycl;
            let beginInOrder = tempCars[newIndex].in_order;
            tempCars.forEach(item => {
                updateCars.push({
                    plate_number: item.plate_number,
                    in_order: beginInOrder++,
                });
            });
            this.updateInOrder(updateCars);
        },
        // 点击拖动位置更换后的事件 废弃
        dragUpdate(e) {
            const updateCars = [];
            const newIndex = e.newIndex;
            const oldIndex = e.oldIndex;
            const diffIndex = Math.abs(newIndex - oldIndex);
            // 直接交换in_order
            if (diffIndex === 1 && this.carList.kycl[oldIndex].in_order !== this.carList.kycl[newIndex].in_order) {
                updateCars.push({
                    plate_number: this.carList.kycl[oldIndex].plate_number,
                    in_order: this.carList.kycl[newIndex].in_order,
                }, {
                    plate_number: this.carList.kycl[newIndex].plate_number,
                    in_order: this.carList.kycl[oldIndex].in_order,
                });
                return this.updateInOrder(updateCars);
            }
            const midIndex = Math.round((this.carList.kycl.length - 1) / 2);
            let tempCars = [];
            let beginInOrder = 0;
            if (newIndex <= midIndex) {
                tempCars = this.carList.kycl.slice(0, newIndex + 1);
                const lastInOrder = this.carList.kycl[newIndex + 1].in_order;
                beginInOrder = lastInOrder - tempCars.length;
            } else {
                tempCars = this.carList.kycl.slice(newIndex);
                beginInOrder = this.carList.kycl[newIndex - 1].in_order + 1;
            }
            tempCars.forEach(item => {
                updateCars.push({
                    plate_number: item.plate_number,
                    in_order: beginInOrder++,
                });
            });
            this.updateInOrder(updateCars);
        },
        // 更新车辆排序号
        updateInOrder(data) {
            const submitData = {
                mixstation_code: this.currentStation,
                vehicles: data,
            };
            this.$axios
                .post('/interfaceApi/production/vehicleinfo/reorder', submitData)
                .then(res => {
                    this.getVehicle(this.currentStation);
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
    },
};

</script>
<style lang='stylus'>
.car-wrapper
    width 100%
    height 100%
    overflow hidden
.car-status-anage
    width 100%
    height 100%
    overflow-y auto !important
// 头部区域
.car-state-title
    display flex
    padding-left 0.14rem
    border-radius: .04rem;
    width 100%
    overflow-x:auto
.bg-purple-dark
    background: #99a9bf;
.bg-purple
    background: #EEEEEE;
    color #000
.bg-purple-light
    background: #e5e9f2;
.grid-content
    position relative
    height: .48rem
    width 1.28rem
    border-radius: .04rem
    margin-right .14rem
    font-size .18rem
    display flex
    justify-content center
    align-items center
    margin-top .24rem
    margin-bottom .14rem
    cursor pointer
    padding 0 5px
    .grid-title
        width 100%
        text-align center
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
.row-bg
    padding: .1rem 0;
    background-color: #f9fafc
.contene_active
    margin-top: .14rem;
    height: .58rem;
.select-style-bottom
    width 100%
    height .06rem
    position absolute
    left 0
    bottom 0
    display flex
    justify-content center
    .bottom_div
        width: 60%;
        height: 100%;
        background-color: #CDE3FF;
        -webkit-transform: perspective(0.1em) rotateX(5deg);

.select-style
    position absolute
    top 0
    lef 0
    width 100%
    height 100%
    border-radius .04rem
    display flex
    align-items center
    justify-content center
    background #006EFF !important
    color #fff !important
    span
        position absolute
        color white
        bottom 0
        right 0
        font-size .2rem
        z-index 2
    .border-right
        position absolute
        width: 0;
        height: 0;
        border-bottom: .3rem solid #D80016;
        border-left: .3rem solid transparent;
        bottom 0
        right 0
// 车辆信息显示
.vehicle-field
    margin-top -.2rem
    .inside-car
        background #fff
        padding 0 .2rem .2rem .2rem
    .outside-car
        background #fff
        margin-top .22rem
        padding 0 .2rem .2rem .2rem
        .car_main
            width 100%
            height 100%
            display flex
        .car_main_2
            margin-top .2rem
            display flex
            .main_left
                margin-right .1rem
                width 67.3%
            .main_right
                flex 1
            >div
                display flex
    .vehicle-title
        display flex
        font-size .24rem
        color #333333
        font-weight 600
        height: .6rem;
        align-items center
        margin-bottom .2rem
        padding-top .2rem
        &:before
            content:" ";
            width .12rem
            height .4rem
            display block
            background-color #1179DA
            margin 0 .12rem 0 0rem
    .title-class
        display inline-block
        padding 0.06rem 0.3rem 0.08rem 0.13rem
        font-size 0.18rem
        background #F6FBF7
        border-left none
        border-top none
        margin-top 0.01rem
        width 100%
        position relative
        color #3BA271 !important
        .iconfont
            position absolute
            right 0
            font-size .4rem
            top 0
            line-height .4rem
            background #fff
        .title_icon1
            color #F6FBF7
        .title_icon2
            color #f6fcfe
        .title_icon3
            color #fefbf4
        .title_icon4
            color #f6f9fe
        .title_icon5
            color #fcfdf7
        .title_icon6
            color #fcf5f5
            line-height .45rem !important
    .available
        border .01rem solid #D7D7D7
        min-height 1rem
    .only-available-style
        border 1px solid #556FC6
        width 100%
        .title-class
            background #f6f9fe
            color #556FC6 !important
    .only-available-style2
        border 1px solid #91CD77
        width 100%
        .title-class
            background #fcfdf7
            color #91CD77 !important
    .only-available-style3
        border 1px solid #DD4F4C
        width 100%
        .title-class
            background #fcf5f5 !important
            color #DD4F4C !important
    .show-car-info
        width 100%
        display flex
        min-height 50px
        flex-wrap: wrap
        dl
            width .5rem
            height .6rem
            border 1px solid #3BA271
            margin .2rem
            cursor pointer
            position relative
        dd
            height .37rem
            display flex
            flex-direction column
            align-items center
            justify-content center
            background #ECF8EE
            span
                color #3BA271
                font-size .32rem
        .car-angle-sign
            display inline-block
            width 100%
            height .2rem
            font-weight normal
            color #fff
            font-size .1rem
            // border-radius 0 0 1rem 0
            position absolute
            top calc(50%)
            line-height .2rem
            div
                width 100%
                height 100%
                transform scale(.8)
                white-space nowrap
        .car-angle-sign-driver
            background:#EBAC00
        .car-angle-sign-task
            background #3BA271
        .car-angle-sign-pull
            background #FC8550
        dt
            font-size .14rem
            width 100%
            height .2rem
            background #3BA271
            color #fff
            text-align center
            line-height .2rem
    .blue-class dl
        border 1px solid #5DB7D9
        dd span
            color #5DB7D9
        dt
            background #5DB7D9
    .origin-class dl
        border 1px solid #FC8550
        dd span
            color #FC8550
        dt
            background #FC8550
    .other-class
        dl
            border 1px solid #02bdfb
            dd span
                color #02bdfb
            dt
                background #02bdfb
    .grey-class dl
        border 1px solid #556FC6
        dd span
            color #556FC6
        dt
            background #556FC6
    .relve-class dl
        border 1px solid #91CD77
        dd span
            color #91CD77
        dt
            background #91CD77
    .red-class dl
        border 1px solid #DD4F4C
        dd span
            color #DD4F4C
        dt
            background #DD4F4C
.car-detailed-info
    width 2.82rem
    background: #f5f8fe;
    box-shadow:0rem .03rem .07rem 0rem rgba(0, 0, 0, 0.2);
    z-index 7
    position fixed
    .car-detailed-box
        background #fff
        margin .07rem
    h3
        width 100%
        line-height .3rem
        background #4673CE
        color #fff
        text-align center
        font-size .16rem
    p
        font-size .14rem
        line-height .3rem
        padding-left .2rem
        display flex
        span
            display block
            margin-right .09rem
    .button-style
        width 100%
        display flex
        align-items center
        justify-content space-between
        height .6rem
        border-top .07rem solid #f5f8fe
        padding 0 .1rem
        font-size .16rem
        text-align center
        line-height .27rem
        div
            width .9rem
            height .3rem
            &:nth-child(1)
                background none
                border 1px solid #1577D2
                color #1577D2
            &:nth-child(2)
                background none
                border 1px solid #D80016
                color #D80016
                margin 0 .1rem
            &:nth-child(3)
                background none
                border 1px solid #91CD77
                color #91CD77
            h5{
                font-weight normal
            }


.main_head_title
    width .3rem
    min-height 100%
    font-size .18rem
    line-height .24rem
    background #3BA271
    color #fff
    display flex
    text-align center
    align-items center
    border-radius .06rem .02rem
    margin-right .1rem
.main_head_title2
    background #556FC6
.main_head_title3
    background #91CD77
.main_head_title4
    background #DD4F4C
.main_head_main
    width 100%
    heigth 49%
    .available_usable
        margin-top 0 !important
        border: 1px solid #3BA271;
    .main_bottom
        margin-top .1rem
        height 49%
        display flex
        .available
            width 33.2%
        .available_one
            border 1px solid #5DB7D9
            .title-class
                background #f6fcfe
                color #5DB7D9 !important
        .available_two
            margin 0 .1rem
            border 1px solid #FC8550
            .title-class
                background: #fefbf4
                color #FC8550 !important
</style>
